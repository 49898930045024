<template>
    <div class="clock">
      <div class="outer-circle"></div>
      <div class="hand hour-hand" :style="hourHandStyle"></div>
      <div class="hand minute-hand" :style="minuteHandStyle"></div>
      <div class="hand second-hand" :style="secondHandStyle"></div>
      <div class="center"></div>
      <div v-for="num in 12" :key="num" class="number" :style="getNumberPosition(num)">{{ num }}</div>
      <div v-for="tick in 60" :key="tick" class="tick" :style="getTickPosition(tick)"></div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        time: new Date(),
      };
    },
    computed: {
      hourHandStyle() {
        const hours = this.time.getHours() % 12;
        const minutes = this.time.getMinutes();
        return {
          transform: `translate(-50%, -100%) rotate(${hours * 30 + minutes * 0.5}deg)`,
        };
      },
      minuteHandStyle() {
        const minutes = this.time.getMinutes();
        return {
          transform: `translate(-50%, -100%) rotate(${minutes * 6}deg)`,
        };
      },
      secondHandStyle() {
        const seconds = this.time.getSeconds();
        return {
          transform: `translate(-50%, -100%) rotate(${seconds * 6}deg)`,
        };
      },
    },
    methods: {
      updateClock() {
        this.time = new Date();
      },
      getNumberPosition(num) {
        const angle = (num - 3) * 30;
        const radius = 80;
        const x = radius * Math.cos(angle * (Math.PI / 180));
        const y = radius * Math.sin(angle * (Math.PI / 180));
        return { transform: `translate(${x}px, ${y}px)` };
      },
      getTickPosition(tick) {
        const angle = tick * 6;
        return {
          position: 'absolute',
          left: '50%',
          top: '50%',
          width: tick % 5 === 0 ? '3px' : '2px',
          height: tick % 5 === 0 ? '10px' : '5px',
          background: tick % 5 === 0 ? 'gold' : 'silver',
          transform: `rotate(${angle}deg) translateY(-90px)`,
          transformOrigin: '50% 50%',
        };
      },
    },
    mounted() {
      setInterval(this.updateClock, 1000);
    },
  };
  </script>
  
  <style scoped>
  .clock {
    width: 220px;
    height: 220px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background: radial-gradient(circle, #2c2c2c 50%, #1a1a1a 100%);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5), inset 0 2px 5px rgba(255, 255, 255, 0.2);
  }
  
  .outer-circle {
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 50%;
    border: 5px solid #ccc;
    box-shadow: inset 0 0 10px rgba(255, 255, 255, 0.3);
  }
  
  .hand {
    position: absolute;
    border-radius: 5px;
    transform-origin: 50% 100%;
    left: 50%;
    top: 50%;
  }
  
  .hour-hand {
    width: 6px;
    height: 50px;
    background: gold;
  }
  
  .minute-hand {
    width: 4px;
    height: 70px;
    background: silver;
  }
  
  .second-hand {
    width: 2px;
    height: 80px;
    background: red;
  }
  .second-hand {
  transition: transform 0.2s cubic-bezier(0.4, 2.3, 0.3, 1);
}
  .center {
    width: 12px;
    height: 12px;
    background: gold;
    border: 2px solid white;
    border-radius: 50%;
    position: absolute;
    box-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
  }
  
  .number {
    position: absolute;
    font-size: 20px;
    font-weight: bold;
    color: white;
    text-shadow: 0 2px 3px rgba(0, 0, 0, 0.5);
  }
  
  .tick {
    position: absolute;
    width: 2px;
    background: silver;
    transform-origin: 50% 50%;
  }
  </style>
  
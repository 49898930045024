<template>
  <div class="daily-alert" :style="alertStyle">
    <p>{{ message }}</p>

    <!-- 编辑图标，点击后切换输入框和更新按钮的显示状态 -->
     <a-tooltip title="点击编辑你的motto">
      <EditOutlined @click="toggleEdit" />
     </a-tooltip>
  

    <!-- 如果处于编辑模式，显示输入框和更新按钮 -->
    <div v-if="isEditing" class="edit-container">
      <input v-model="inputMessage" type="text" :placeholder="'Edit your message'" />
      <a-tooltip title="点击提交">
        <CheckCircleOutlined  @click="updateMessage"/>
      </a-tooltip>
     
    </div>
  </div>
</template>

<script>
import { EditOutlined,CheckCircleOutlined } from '@ant-design/icons-vue'
import axios from 'axios'

export default {
  components: {
    EditOutlined,
    CheckCircleOutlined
  },
  name: "DailyAlert",
  data() {
    return {
      isEditing: false,  // 控制是否进入编辑模式
      inputMessage: '',  // 用于绑定输入框内容
      message: '', // 存储从接口获取的消息
    };
  },
  computed: {
    alertStyle() {
      return {
        backgroundColor: "#fef3c7", // 默认背景色浅黄色
        color: "#333", // 默认文字颜色
        padding: "15px",
        borderRadius: "8px",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        fontSize: "16px", // 默认字体大小
        textAlign: "center",
        margin: "10px auto",
        maxWidth: "80%"
      };
    }
  },
  methods: {
    // 切换编辑状态
    toggleEdit() {
      this.isEditing = !this.isEditing;
    },
    // 更新消息内容并发送 POST 请求
    updateMessage() {
      // 发送 POST 请求到接口
      axios.post('/v1/updatemotto', {
        motto: this.inputMessage
      })
      .then(response => {
        console.log(response.data)
        // 如果更新成功，设置 message 为输入框中的值
        this.message = this.inputMessage;
        this.isEditing = false;  // 退出编辑模式
      })
      .catch(error => {
        console.error("There was an error updating the message:", error);
      });
    },
    // 获取服务器数据
    fetchMessage() {
      axios.get('/v1/usermotto')
        .then(response => {
          // 假设返回的数据格式是 { motto: 'some message' }
          this.message = response.data.motto; // 将接口返回的 motto 设置为 message
          this.inputMessage = this.message; // 初始化输入框内容
        })
        .catch(error => {
          console.error("There was an error fetching the message:", error);
        });
    }
  },
  created() {
    this.fetchMessage(); // 在组件创建时拉取数据
  }
};
</script>

<style scoped>
.daily-alert {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.edit-container {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.edit-container input {
  margin-right: 10px;
  padding: 5px;
  font-size: 16px;
}

.edit-container button {
  padding: 5px 10px;
  background-color: #4caf50;
  color: white;
  border: none;
  cursor: pointer;
}

.edit-container button:hover {
  background-color: #45a049;
}
</style>
